<template>
  <b-card>
    <b-overlay
      :show="!rows"
      rounded="sm"
    >
      <div class="invoice-register-table pt-1 mb-1">
        <!--        <h3>Maxmind Credit: <span class="remaining-credit">$1000</span></h3>-->
        <date-range-picker
          ref="picker"
          v-model="dates"
          class="stat-picker"
          style="width: auto;margin-bottom: 15px;"
          opens="right"
          :ranges="ranges"
          @update="fetchAntiFraudUsers"
        >
          <!--          @update="getNewStats"-->
          <div
            slot="input"
            slot-scope="picker"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
              class="stat-calendar"
            />
            {{ date(picker.startDate) }} - {{ date(picker.endDate) }}
            <!--            <span v-if="getNewStatsSpinner">-->
            <!--              <b-spinner-->
            <!--                small-->
            <!--                type="grow"-->
            <!--              />-->
            <!--            </span>-->
          </div>
          <div
            slot="footer"
            slot-scope="data"
            class="slot"
          >
            <div class="custom-search d-flex justify-content-end">
              <b-button
                size="sm"
                variant="outline-primary"
                @click="data.clickCancel"
              >
                Cancel
              </b-button>&nbsp;
              <b-button
                size="sm"
                variant="primary"
                @click="data.clickApply"
              >
                Apply
              </b-button>
            </div>
          </div>
        </date-range-picker>

        <!-- search input -->
        <div class="custom-search mb-1 ir-top-box-right">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="rows"
          :columns="columns"
          :rows="rows"
          :sort-options="{
            enabled: true,
          }"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :is-loading.sync="isLoading"
        >
          <template slot="loadingContent">
            <div
              class="my-1 d-flex spinner-border text-primary justify-content-center"
              style="width: 2.5rem; height: 2.5rem; margin: auto;"
              role="status"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Invoice No -->
            <span v-if="props.column.field === 'domain'">
              <router-link
                :to="`/user/details/${props.row.uid}`"
              >
                {{ props.row.domain }}
              </router-link>
            </span>
            <!-- Column: Invoice No -->
            <span v-else-if="props.column.field === 'package_name'">
              {{ props.row.package_name }}
            </span>

            <span v-else-if="props.column.field === 'date'">
              {{ formatDate(props.row.date) }}
            </span>

            <span v-else-if="props.column.field === 'profit'">
              {{ formatCurrencyNumber(props.row.profit / 10000) }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','150']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BCard, BOverlay, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import axios from '@/libs/axios'
import DateRangePicker from 'vue2-daterange-picker'

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    BCard,
    BButton,
    DateRangePicker,
  },
  data() {
    return {
      invoiceLinkText: '',
      pageLength: 50,
      dir: false,
      columns: [
        {
          label: 'Domain',
          field: 'domain',
          sortFn: this.sortFnDomain,
        },
        {
          label: 'Package',
          field: 'package_name',
          sortFn: this.sortFnPackage,
        },
        {
          label: 'Credits Charged',
          field: 'credit_spent',
          sortFn: this.sortFnCredits,
        },
        {
          label: 'Credits Spent',
          field: 'maxmind_spent',
          sortFn: this.sortFnMaxMind,
        },
        {
          label: 'Profit',
          field: 'profit',
          sortFn: this.sortFnProfit,
        },
        {
          label: 'Date',
          field: 'date',
          sortFn: this.sortFnIssueDate,
        },
      ],
      rows: [],
      searchTerm: '',
      dates: {
        startDate: null,
        endDate: null,
      },
      ranges: {},
      isLoading: false,
    }
  },
  mounted() {
    this.dates.startDate = this.formatDate3(moment().subtract(10, 'd'))
    this.dates.endDate = this.formatDate3(moment())

    this.fetchAntiFraudUsers()
    this.dataPickerRanges()
  },
  methods: {
    formatCurrencyNumber(number) {
      if (Number.isNaN(number)) return '0'
      return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(number)
    },

    sortFnIssueDate(x, y, col, rowX, rowY) {
      const value1 = Date.parse(this.filterFormatDate(rowX.date))// get x
      const value2 = Date.parse(this.filterFormatDate(rowY.date))// get y

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)); // eslint-disable-line
    },
    sortFnCredits(x, y, col, rowX, rowY) {
      const value1 = rowX.credit_spent// get x
      const value2 = rowY.credit_spent// get y

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)); // eslint-disable-line
    },
    sortFnMaxMind(x, y, col, rowX, rowY) {
      const value1 = rowX.maxmind_spent// get x
      const value2 = rowY.maxmind_spent// get y

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)); // eslint-disable-line
    },
    sortFnProfit(x, y, col, rowX, rowY) {
      const value1 = rowX.profit// get x
      const value2 = rowY.profit// get y

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)); // eslint-disable-line
    },
    sortFnPackage(x, y, col, rowX, rowY) { // eslint-disable-line

      let value1 = rowX.package_name // get x
      let value2 = rowY.package_name // get y
      value1 = String(value1)
      value2 = String(value2)

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)); // eslint-disable-line
    },
    sortFnDomain(x, y, col, rowX, rowY) { // eslint-disable-line

      let value1 = rowX.domain // get x
      let value2 = rowY.domain // get y
      value1 = String(value1)
      value2 = String(value2)

      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)); // eslint-disable-line
    },
    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },
    date(date) {
      const datum = new Date(date)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Intl.DateTimeFormat('en-US', options).format(datum)
    },
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    formatDate2(date) {
      const datum = new Date(date)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Intl.DateTimeFormat('en-US', options).format(datum)
    },
    formatDate3(Date) {
      return moment(Date).format('YYYY-MM-DD')
    },
    dataPickerRanges() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      const yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)

      const thisMonthStart = new Date(this.dates.startDate)
      const thisMonthEnd = new Date(this.dates.endDate)

      const last7days = new Date()
      last7days.setDate(today.getDate() - 7)
      last7days.setHours(0, 0, 0, 0)

      const ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        'Last 7 days': [last7days, today],
        'This month': [thisMonthStart, thisMonthEnd],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      }
      this.ranges = ranges
    },
    fetchAntiFraudUsers() {
      this.isLoading = true
      const uri = `/api/antifraud/get_clients?startDate=${moment(this.dates.startDate).format('YYYY-MM-DD')}&endDate=${moment(this.dates.endDate).format('YYYY-MM-DD')}`
      axios.get(uri, this.dates).then(res => {
        this.isLoading = false
        this.rows = res.data
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.remaining-credit {
  color: #c23f8d;
  font-weight: bold;
}

.invoice-table {
  thead tr {
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}
</style>

<style lang="scss" scoped>
.dark-layout a {
  color: #fb50a1 !important;
}
</style>
